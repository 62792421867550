<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:43:43
-->
<template>
	<div class="activityMy">
		<common-title :title="'活动签到详情'" />
		<div class="activityMy-content">
			<div class="activityMy-table">
				<el-table :data="myData" border>
					<el-table-column prop="regNum" label="注册号">
						<template slot-scope="scope">
							<span>{{scope.row.volunteer.regNum}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="姓名">
						<template slot-scope="scope">
							<span>{{scope.row.volunteer.name}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="studentId" label="学号">
						<template slot-scope="scope">
							<span>{{scope.row.volunteer.studentId}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="organization" label="性别">
						<template slot-scope="scope">
							<span>{{scope.row.volunteer.gender == true?'男':'女'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="faculty" label="学院班级">
						<template slot-scope="scope">
							<span>{{scope.row.volunteer.faculty}}</span>
						</template>
					</el-table-column>
					<el-table-column width="300px" prop="activityTimeStar" label="所选时间">
						<template slot-scope="scope">
							<div class="flex-column">
								<span v-for="(item, index) in scope.row.recruitTimes" :key="index">{{`${dateFormat(item.starTime)} 至 ${dateFormat(item.endTime)}`}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="recruitAddress" label="所选地点">
					</el-table-column>
					<el-table-column prop="signInTime" label="签到时间">
						<template slot-scope="scope">
							<span>{{scope.row.signInTime}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="signOutTime" label="签退时间">
						<template slot-scope="scope">
							<span>{{scope.row.signOutTime}}</span>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		detailActivitySign
	} from "@/api/activity";
	export default {
		data() {
			return {
				myData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				activitySignId: "",
			};
		},
		mounted() {
			this.activitySignId = this.$route.query.activitySignId
			this.activitySignDetail()
		},
		beforeRouteLeave(to,from,next){
			to.meta.isAlive = true
			next()
		},
		methods: {
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(":00", "")
				}
			},
			currentChange(val) {
				this.pageIndex = val;
				this.myData = [];
				this.activitySignDetail();
			},
			activitySignDetail() {
				let params = {
					// pageIndex: this.pageIndex,
					// pageSize: this.pageSize,
					id: this.activitySignId
				}
				detailActivitySign(params).then((res) => {
					if (res.code == 0) {
						this.myData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			}
		}
	};
</script>

<style lang="scss" scoped>
	.flex-column {
		display: flex;
		flex-direction: column;
	}
	.activityMy {
		.activityMy-content {
			padding: 0 20px 20px 20px;

			.activityMy-table {
				margin-top: 20px;

				.table-action-red {
					color: red !important;
				}

				.table-action-href {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 20px;
					color: #1CABB9;
					text-decoration: underline;
				}
			}
		}
	}
</style>
